
import { Getter } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import { TeamFunctions } from "@/store/modules/activity-account.store";

@Component({
    components: {
        contactCard: () => import("./contact-card.vue"),
        userImage: () => import("@/components/general/user-image.vue"),
    },
})
export default class PageSellerPartContactHeader extends Vue {
    @Getter("activity/viewing") activity!: Activity;

    @Prop({ default: "card" }) mode!: "list" | "card";

    get teamMembers() {
        if (!this.activity || !this.activity.assigned_to) {
            return [];
        }

        return this.activity.assigned_to;
    }

    get backofficeContact() {
        if (!this.teamMembers.length) {
            return null;
        }

        return this.teamMembers.find((tm) => tm.type === TeamFunctions.backoffice);
    }

    get legalContact() {
        if (!this.teamMembers.length) {
            return null;
        }

        return this.teamMembers.find((tm) => tm.type === TeamFunctions.legal);
    }

    get salesContact() {
        if (!this.teamMembers.length) {
            return null;
        }

        return this.teamMembers.find((tm) => tm.type === TeamFunctions.buyer);
    }
}
